import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ScreenConfigType } from 'src/app/types/screenConfig.type';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public screenConfig: BehaviorSubject<ScreenConfigType> = new BehaviorSubject({} as ScreenConfigType);

  constructor(private router: Router) { }

  async getConfig(id: string) {
    let configURL = environment.apiUrl + '/screens/' + id + '/config';
    const response: Response = await fetch(configURL);
    const data = await response.json();
    
    if (response.status != 200) {
      this.router.navigate(['error'], {
        state: {
          "STATUS": response.status,
          "MESSAGE": data.message ?? 'ERROR',
          "PREV_ROUTE": configURL
        }
      })
    }
    return data;
  }
}
