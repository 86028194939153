<div class="{{ queueSectionClasses.turnContainer }}">
  <div class="{{ queueSectionClasses.titles }}">TURNO</div>
  <div class="{{ queueSectionClasses.turn }}">
    {{
      queue.currentTurn.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
    }}
  </div>
</div>
<div *ngIf="moduleEnabled" class="{{ queueSectionClasses.moduleContainer }}">
  <div class="{{ queueSectionClasses.titles }}">MÓDULO</div>
  <div class="{{ queueSectionClasses.module }}">
    {{ queue.currentModule }}
  </div>
</div>
